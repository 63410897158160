import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { ServiceService } from 'src/app/service.service';
import { UntypedFormBuilder,Validators, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
 displayStyle="none";
 numberOfCharacters2 = 0;
  maxNumberOfCharacters=400
  interaction = {
    textValue: ''
  };
 contactForm:UntypedFormGroup
    constructor(public location: Location, private router: Router,
        private service: ServiceService, private fb: UntypedFormBuilder) {
            this.contactForm = this.fb.group({
                name:['',Validators.compose([Validators.required,Validators.maxLength(40)])],
                email: ['', Validators.compose([Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), Validators.minLength(1)])],
                company: ['',Validators.compose([Validators.required,Validators.maxLength(50)])],
                message: ['',Validators.compose([Validators.required,Validators.pattern('[a-zA-Z0-9 ,.?\\-]+'),Validators.maxLength(400)])],
                mobile: ['',Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"),Validators.maxLength(15)])],
                
          
            })
         }
         get f() { return this.contactForm.controls; }
    

    ngOnInit() {


      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
       } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
       }
     });
     this.location.subscribe((ev:PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
     });
    }
    onSubmit() {
        this.service.contactus(this.contactForm.value).subscribe(
            result => {
              console.log(this.contactForm.value)
              console.log(result)
              alert('Your Request has been send to Sales Team');
              //   this.contactForm.reset()
                window.location.reload()
            },
            err => {
      
      
            }
          )
    }
    openPopup() {
        this.displayStyle = "block";
      }
      closePopup() {
        this.contactForm.reset()
        this.displayStyle = "none";
        
      }
      onModelChange(textValue: string): void {
        this.numberOfCharacters2 = textValue.length;
      }
    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === '#/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '#/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule, ExtraOptions } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { ProfileComponent } from "./profile/profile.component";

import { LandingComponent } from "./landing/landing.component";
import { ProductsComponent } from "./products/products.component";
import { ServiceComponent } from "./service/service.component";
import { IccmenuComponent } from "./iccmenu/iccmenu.component";
import { IcxmenuComponent } from "./icxmenu/icxmenu.component";
import { IixComponent } from "./iix/iix.component";
import { IcareComponent } from "./icare/icare.component";

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "user-profile", component: ProfileComponent },

  { path: "landing", component: LandingComponent },
  { path: "product", component: ProductsComponent },
  { path: "service", component: ServiceComponent },
  { path: "iccmenu", component: IccmenuComponent },
  { path: "icxmenu", component: IcxmenuComponent },
  { path: "iixmenu", component: IixComponent },
  { path: "icare", component: IcareComponent },

  { path: "", redirectTo: "landing", pathMatch: "full" },
];
const routerOption: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled",
};

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, routerOption),
  ],
  exports: [],
})
export class AppRoutingModule {}

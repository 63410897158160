import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import {HttpClientModule} from '@angular/common/http';
import { AppComponent } from './app.component';

import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { ProductsComponent } from './products/products.component';
import { ServiceComponent } from './service/service.component';
import { IccmenuComponent } from './iccmenu/iccmenu.component';
import { IcxmenuComponent } from './icxmenu/icxmenu.component';
import { IixComponent } from './iix/iix.component';
import { IcareComponent } from './icare/icare.component';


@NgModule({
  declarations: [
    AppComponent,
   
    LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    ProductsComponent,
    ServiceComponent,
    IccmenuComponent,
    IcxmenuComponent,
    IixComponent,
    IcareComponent],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule,
    HttpClientModule,
    ReactiveFormsModule
   
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ReturnStatement } from '@angular/compiler';


@Injectable({
  providedIn: 'root'
})
export class ServiceService {
// baseurl='http://localhost:3000'
// baseurl='http://13.234.117.104:3000'
  constructor(private http: HttpClient,) {

   }
contactus(contactForm
  ){
  // return this.http.post<any>('http://localhost:4000/api/v2/contactus',contactForm);
  return this.http.post<any>('https://api.ibaptech.sbs/api/v2/contactus',contactForm)
}

}

import { NONE_TYPE } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {
displayStyle="none";
//displayStyle_one = "none";
displayStyle1="none";

displayStyle_one = "none";
displayStyle_two = "none";
displayStyle_three = "none";
displayStyle_four = "none";

  constructor() { }

  ngOnInit(){

    // openPopup() {
    //   this.displayStyle = "block";
    // }
    // closePopup() {
    //   this.displayStyle = "none";
    // }
  }
  openPopup(id) {
    //this.displayStyle = "block";
    if(id == 1){
      this.displayStyle_one = "block";
    }else if(id == 2){
      this.displayStyle_two = "block";
    }else if(id == 3){
      this.displayStyle_three = "block";
    }else if(id == 4){
      this.displayStyle_four = "block";
    }
  }
  closePopup(id) {
    if(id == 1){
      this.displayStyle_one = "none";
    }else if(id == 2){
      this.displayStyle_two = "none";
    }else if(id == 3){
      this.displayStyle_three = "none";
    }else if(id == 4){
      this.displayStyle_four = "none";
    }
  }



}

import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  numberOfCharacters2 = 0;
  maxNumberOfCharacters=400
  interaction = {
    textValue: ''
  };
    contactForm: UntypedFormGroup
    constructor(private service: ServiceService,
        private fb: UntypedFormBuilder) {
            this.contactForm = this.fb.group({
                name:['',Validators.compose([Validators.required,Validators.maxLength(40)])],
                email: ['', Validators.compose([Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), Validators.minLength(1)])],
                company: ['',Validators.compose([Validators.required,Validators.maxLength(50)])],
                message: ['',Validators.compose([Validators.required,Validators.pattern('[a-zA-Z0-9 ,.?\\-]+'),Validators.maxLength(400)])],
                mobile: ['',Validators.compose([Validators.required, Validators.pattern("^[0-9]*$"),Validators.maxLength(15)])],
                
          
            })
         }
         get f() { return this.contactForm.controls; }
    ngOnInit() { }
    onModelChange(textValue: string): void {
      this.numberOfCharacters2 = textValue.length;
    }
    onSubmit() {
        this.service.contactus(this.contactForm.value).subscribe(
            result => {
              console.log(this.contactForm.value)
              console.log(result)
              alert('Your Request has been send to Sales Team');
            //   this.contactForm.reset()
              window.location.reload()
            //   setTimeout(() => {
            //     this.spinnerservice.hide();
            //    }, 1000);
            //    var x;
            //    x=confirm("Are You Sure You Want To Navigate To View Page")
            //    if(x==true){
            //     this.router.navigate(['/view']);}
            //    else{
            //      this.regForm.reset()
            //    }
               
            },
            err => {
      
      
            }
          )
        

       
        

    }

}
